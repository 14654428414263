// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.disabled-save-button {
    cursor: no-drop !important; 
    pointer-events: none;
    background-repeat: no-repeat;
    background-position: center;
  }
  .delete-icon {
    cursor: pointer; /* Changes cursor to pointer on hover */
}

.small-column {
  width: 5%; 
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/ui/admin/user/user.component.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,oBAAoB;IACpB,4BAA4B;IAC5B,2BAA2B;EAC7B;EACA;IACE,eAAe,EAAE,uCAAuC;AAC5D;;AAEA;EACE,SAAS;EACT,kBAAkB;AACpB","sourcesContent":[".disabled-save-button {\r\n    cursor: no-drop !important; \r\n    pointer-events: none;\r\n    background-repeat: no-repeat;\r\n    background-position: center;\r\n  }\r\n  .delete-icon {\r\n    cursor: pointer; /* Changes cursor to pointer on hover */\r\n}\r\n\r\n.small-column {\r\n  width: 5%; \r\n  text-align: center;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
