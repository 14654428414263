import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from "@angular/router";
import { AdminComponent } from "./admin.component";
import { AdminNavMenuComponent } from "./admin-nav-menu/admin-nav-menu.component";
import { AdminHeaderComponent } from "./admin-header/admin-header.component";
import { AdminFooterComponent } from "./admin-footer/admin-footer.component";
import { NgxTippyModule } from "ngx-tippy-wrapper";
import { AdminApiService } from "../../api/adminapi.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptor } from "../../interceptor/AuthInterceptor";
import { ConfirmationDialogComponent } from "./confirmation-dialog/confirmation-dialog.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { FormsModule } from "@angular/forms";
import { UserComponent } from "./user/user.component";
import { DataTablesModule } from "angular-datatables";

const routes: Routes = [
  {
    path: "",
    component: AdminComponent,
    children: [
      {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full",
        data: { breadcrumb: "" },
      },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
        data: { breadcrumb: "Dashboard" },
      },
      {
        path: "events",
        loadChildren: () =>
          import("./events/events.module").then((m) => m.EventsModule),
        data: { breadcrumb: "Events" },
      },
      {
        path: "components",
        loadChildren: () =>
          import("./components/components.module").then(
            (m) => m.ComponentsModule
          ),
        data: { breadcrumb: "Components" },
      },
      {
        path: "reports",
        loadChildren: () =>
          import("./reports/reports.module").then((m) => m.ReportsModule),
        data: { breadcrumb: "Reports" },
      },
      {
        path: "subscribers",
        loadChildren: () =>
          import("./subscribers/subscribers.module").then(
            (m) => m.SubscribersModule
          ),
        data: { breadcrumb: "Subscribers" },
      },
      {
        path: "settings",
        loadChildren: () =>
          import("./setting/setting.module").then((m) => m.SettingsModule),
        data: { breadcrumb: "Settings" },
      },
      {
        path: "user",
        loadChildren: () =>
          import("./user/user.module").then((m) => m.userModule),
        data: { breadcrumb: "User " },
      },
      {
        path: "apikeys",
        loadChildren: () =>
          import("./api-keys/api-keys.module").then((m) => m.apiKeysModule),
        data: { breadcrumb: "API Keys " },
      },
    ],
  },
];

@NgModule({
  declarations: [
    AdminComponent,
    AdminNavMenuComponent,
    AdminHeaderComponent,
    AdminFooterComponent,
    ConfirmationDialogComponent,
    UserComponent
  ],
  imports: [
    CommonModule,
    NgxTippyModule,
    RouterModule.forChild(routes),
    ImageCropperModule,
    FormsModule,
    DataTablesModule,
  ],
  providers: [
    AdminApiService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
})
export class AdminModule {}
