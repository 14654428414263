// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.table tbody td,
.table thead th {
  border: #dedede solid 1px !important;
}
.table thead th {
  font-weight: 800;
}

.table tbody {
 border:none;
}

table.dataTable {
    margin-top:0 !important;
    margin-bottom:0 !important;
}


.accordion {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.accordion-item {
  border-bottom: 1px solid #ccc;
}

.accordion-header {
  background-color: #f9f9f9;
  border: none;
  cursor: pointer;
  padding: 1em;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-header:hover {
  background-color: #e9e9e9;
}

.accordion-content {
  display: none;
  padding: 1em;
}

.accordion-content.active {
  display: block;
}

.status-icon {
  color: green;
}

.status {
  float: right;
  color: green;
  font-weight: bold;
}

`, "",{"version":3,"sources":["webpack://./src/app/ui/status/home_new1/home_new1.component.css"],"names":[],"mappings":";AACA;;EAEE,oCAAoC;AACtC;AACA;EACE,gBAAgB;AAClB;;AAEA;CACC,WAAW;AACZ;;AAEA;IACI,uBAAuB;IACvB,0BAA0B;AAC9B;;;AAGA;EACE,uBAAuB;EACvB,sBAAsB;EACtB,kBAAkB;EAClB,uCAAuC;AACzC;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":["\r\n.table tbody td,\r\n.table thead th {\r\n  border: #dedede solid 1px !important;\r\n}\r\n.table thead th {\r\n  font-weight: 800;\r\n}\r\n\r\n.table tbody {\r\n border:none;\r\n}\r\n\r\ntable.dataTable {\r\n    margin-top:0 !important;\r\n    margin-bottom:0 !important;\r\n}\r\n\r\n\r\n.accordion {\r\n  background-color: white;\r\n  border: 1px solid #ccc;\r\n  border-radius: 5px;\r\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.accordion-item {\r\n  border-bottom: 1px solid #ccc;\r\n}\r\n\r\n.accordion-header {\r\n  background-color: #f9f9f9;\r\n  border: none;\r\n  cursor: pointer;\r\n  padding: 1em;\r\n  width: 100%;\r\n  text-align: left;\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n}\r\n\r\n.accordion-header:hover {\r\n  background-color: #e9e9e9;\r\n}\r\n\r\n.accordion-content {\r\n  display: none;\r\n  padding: 1em;\r\n}\r\n\r\n.accordion-content.active {\r\n  display: block;\r\n}\r\n\r\n.status-icon {\r\n  color: green;\r\n}\r\n\r\n.status {\r\n  float: right;\r\n  color: green;\r\n  font-weight: bold;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
