import { Injectable } from '@angular/core';
import { sharedService } from './sharedService';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CustomRoutesServiceService {

  constructor(private sharedService: sharedService,
    private cookieService:CookieService,
          private router:Router) { }

   getTheme() {
    const theme= this.sharedService.getTheme();
    return theme;
   }
}


// import { Injectable } from '@angular/core';
// import { sharedService } from './sharedService';
// import { Router } from '@angular/router';
// import { CookieService } from 'ngx-cookie-service';
// import { map } from 'rxjs/operators';

// @Injectable({
//   providedIn: 'root'
// })
// export class CustomRoutesServiceService {

//   constructor(private sharedService: sharedService,
//     private cookieService:CookieService,
//           private router:Router) { }
//    getTheme() {

//     return this.sharedService.getTheme().pipe(
//       // You can use the map operator to transform the theme value and return it synchronously
//       map((theme) => {
//     //const theme= this.cookieService.get('themee');


//     if (theme === 'Aurora') {
//       this.router.navigate(['']);
//     } else if (theme === 'Bluelack') {
//       this.router.navigate(['']);
//     } else {
//       this.router.navigate(['']);
//     }

//     return theme;
//    }),
//     );
// }
// }
