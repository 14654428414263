import { Component } from '@angular/core';
import { StatusApiService } from '../../../api/statusapi.service';
import { Globals } from '../../../global';
import { Incident, IncidentMonthlyHistory, Region, Zone } from '../../../models/StatusPageModel';


@Component({
  selector: 'app-history-component',
  templateUrl: './history.component.html'
})
export class HistoryComponent {
  incidents: IncidentMonthlyHistory[];
  totalIncidents: number = 0;
  currentIncidents: number = 0;
  range: string = 'Last 90 days';
  region: string = '';
  component: string = '';
  regionOptions: Array<Object> = [{ name: 'All', value: '' }];
  componentOptions: Array<Object> = [{ name: 'All', value: '' }]

  rangeOptions: Array<Object> = [
    { name: 'Most Recent', value: '' }
  ]
  timeZone;

  constructor(private _statusApiService: StatusApiService) {
    this.timeZone= Globals.timeZone;
  }

  ngOnInit() {
    this.getHistoryMetadata();
    this.getHistory();
  }

  getHistory() {
    const requestPayload = this.prepareHistoryViewModel();
    this._statusApiService.GetStatusHistory(requestPayload)
      .subscribe(
        data => {
          if (data && data.success && data.result && data.result.incidentHistoryByMonthAndYear) {
            if (this.incidents && this.incidents.length > 0) {
              data.result.incidentHistoryByMonthAndYear.forEach((item) => {
                let filter = this.incidents.filter(x => x.monthAndYear == item.monthAndYear);
                if (filter && filter.length > 0) {
                  filter[0].incidentHistory.push(...item.incidentHistory)
                }
                else {
                  this.incidents.push(item);
                }
              });
            }
            else {
              this.incidents = data.result.incidentHistoryByMonthAndYear;
            }
            this.totalIncidents = data.result.totalIncidents
            this.getCurrentNumberOfRecords();
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  getHistoryOnDropdownChange() {
    this.incidents = [];
    this.getHistory();
  }

  getCurrentNumberOfRecords() {
    this.currentIncidents = 0;
    this.incidents.forEach((item) => {
      this.currentIncidents += item.incidentHistory.length;
    });
    return this.currentIncidents;
  }


  getHistoryMetadata() {
    this._statusApiService.GetStatusHistoryMetadata()
      .subscribe(
        data => {
          if (data && data.success && data.result) {

            if (data.result.regions && data.result.regions.length > 0) {
              this.regionOptions = [{ name: 'All', value: '' }];

              data.result.regions.forEach((item) => {
                this.regionOptions.push({ name: item, value: item });
              });
            }

            if (data.result.components && data.result.components.length > 0) {
              this.componentOptions = [{ name: 'All', value: '' }];

              data.result.components.forEach((item) => {
                this.componentOptions.push({ name: item, value: item });
              });
            }

            if(data.result.rangeTypes && data.result.rangeTypes.length > 0) {
              this.rangeOptions = [{ name: 'Most Recent', value: '' }];

              data.result.rangeTypes.forEach((item) => {
                this.rangeOptions.push({ name: item, value: item });
              });
            }
          }
        },
        error => {
          console.log(error);
        }
      );
  }


  prepareHistoryViewModel() {
    const historyModel = <any>{};
    historyModel.components = [];
    historyModel.regions = [];
    historyModel.range = this.range;
    historyModel.isPublic = true;
    historyModel.skip = this.incidents ? this.getCurrentNumberOfRecords() : 0;
    historyModel.take = 10;

    if (this.region)
      historyModel.regions.push(this.region);

    if (this.component)
      historyModel.components.push(this.component);

    return historyModel;
  }

  viewMore() {
    this.getHistory();
  }

}
