import { Component, OnInit } from "@angular/core";
import { StatusApiService } from "../../../api/statusapi.service";
import { Globals } from "../../../global";
import {
  Incident,
  StatusPageModel,
  ComponentGroup,
  Component as StatusComponent,
} from "../../../models/StatusPageModel";
import { Observable, timer } from "rxjs";

import { map } from "rxjs/operators";
import { ThemeService } from "src/app/services/theme.services";

@Component({
  selector: "app-home-new3",
  templateUrl: "./home-new3.component.html",
  styleUrls: ["./home-new3.component.css"],
})
export class HomeNew3Component implements OnInit {
  commonRegionName: string = "";
  statusData: StatusPageModel;
  incidents: Incident[];
  maintenances: Incident[];
  searchText: string = "";
  componentGroups: ComponentGroup[] = [];
  toogleThreedot: boolean = false;
  recentIncidents: Incident[] = [];
  loading = null;
  banner: string;

  timeZone;

  $currentDateTime: Observable<Date> = timer(0, 1000).pipe(
    map(() => new Date())
  );

  constructor(private _statusApiService: StatusApiService,private themeService:ThemeService) {
    this.commonRegionName = Globals.commonRegionName;
  }
  darkThemeApply:any=false;

  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  lightTheme=true

  ngOnInit(): void {

    if (this.themeService.isDark) {
      this.lightTheme =false
    } else {
      this.lightTheme =true
    }

    const storedTheme = this.getCookie('theme');

    if (storedTheme) {
      this.darkThemeApply = storedTheme === 'Multi-Region';
    } else {
      this.darkThemeApply = false;
    }
    this.getComponentStatus(true);
    this.getRecentHistory();
    this.reloadIfNecessary();
  }
  reloadIfNecessary(): void {
    const isLoadedBefore = localStorage.getItem("IsLoadedBefore");
    if (isLoadedBefore !== "true") {
      localStorage.setItem("IsLoadedBefore", "true");
      window.location.reload();
    }
  }
  getComponentStatus(_updateRegionZones = false) {
    this._statusApiService.GetCongaComponentsStatus().subscribe(
      (data) => {
        if (
          data &&
          data.success &&
          data.result &&
          data.result.componentGroups &&
          data.result.componentGroups.length > 0
        ) {
          this.statusData = data.result;
          this.componentGroups = this.statusData.componentGroups.map((cg) => ({
            ...cg,
            defaultExpanded: false,
            components: cg.components.map((c) => ({
              ...c,
              componentGroupName: cg.componentGroupName,
            })),
          }));

          this.incidents = this.statusData.incidents;
          this.maintenances = this.statusData.maintenances;
          this.getSortedComponentGroups();
          this.getOverallSystemStatus(this.componentGroups);
        }
      },
      (error) => {
        console.error("Error fetching component status:", error);
      }
    );
  }

  getSortedComponentGroups() {
    // Define the priority levels for sorting
    const statusPriority = {
      "Major Outage": 1,
      "Partial Outage": 2,
      Degradation: 3,
      Operational: 4,
    };

    // Create an array to hold the priority values temporarily
    const groupPriorities = this.componentGroups.map((group) => {
      // Calculate the highest priority status among components in the group
      const highestPriority = Math.min(
        ...group.components.map((component) => {
          // Get the highest priority across all regions for the component
          const componentHighestPriority = Math.min(
            ...component.componentStatus.map((statusObj) => {
              const status = statusObj?.status;
              return statusPriority[status] || Infinity; // Use Infinity for undefined statuses
            })
          );
          return componentHighestPriority;
        })
      );

      return { group, priority: highestPriority }; // Return both group and its priority
    });

    // Sort the temporary array by priority
    groupPriorities.sort((a, b) => a.priority - b.priority);

    // Update componentGroups to be sorted based on the priority
    this.componentGroups = groupPriorities.map((item) => item.group);

    // Now sort the components within each group based on the highest priority status
    this.componentGroups.forEach((group) => {
      group.components.sort((a, b) => {
        // Get the highest priority status for component A
        const highestPriorityA = Math.min(
          ...a.componentStatus.map((statusObj) => {
            const statusA = statusObj?.status;
            return statusPriority[statusA] || Infinity;
          })
        );

        // Get the highest priority status for component B
        const highestPriorityB = Math.min(
          ...b.componentStatus.map((statusObj) => {
            const statusB = statusObj?.status;
            return statusPriority[statusB] || Infinity;
          })
        );

        return highestPriorityA - highestPriorityB;
      });
    });

    // Update statusData to have sorted componentGroups
    this.statusData.componentGroups = this.componentGroups;

    // Log the sorted component groups and components
  }

  isAllSystemsOperational(): boolean {
    return this.componentGroups.every((group) =>
      group.components.every(
        (item) =>
          item.componentStatus[0]?.status?.toLowerCase() === "operational"
      )
    );
  }

  closeDialog(): void {
    const dialog = document.getElementById(
      "exampleDialog1"
    ) as HTMLDialogElement;
    if (dialog) {
      dialog.close();
    }
  }

  getRecentHistory() {
    const recentHistoryPayload = {
      range: "Last 7 days",
      components: [],
      regions: [],
      isPublic: true,
      skip: 0,
      take: 3,
    };

    this._statusApiService.GetStatusHistory(recentHistoryPayload).subscribe(
      (data) => {
        if (data && data.success && data.result) {
          this.recentIncidents =
            data.result.incidentHistoryByMonthAndYear.flatMap(
              (month) => month.incidentHistory
            );
          this.loading = false;
        }
      },
      (error) => {
        console.log("Error fetching recent history:", error);
      }
    );
  }
  getOverallSystemStatus(data) {
    const statusPriority = {
      "Major Outage": 1,
      "Partial Outage": 2,
      Degradation: 3,
      Maintenance: 4,   // Add Maintenance with priority 4
      Operational: 5,   // Update Operational to priority 5
    };

    let highestPriority = 5; // Start with the lowest priority "Operational"
    let banner = "Operational"; // Default banner

    data.forEach((componentGroup) => {
      componentGroup.components.forEach((component) => {
        component.componentStatus.forEach((status) => {
          console.log(status.status);

          const currentStatusPriority = statusPriority[status.status] || Infinity;
          if (currentStatusPriority < highestPriority) {
            highestPriority = currentStatusPriority;
            banner = status.status; // Update banner to the highest priority status found
          }
        });
      });
    });

    this.banner = banner; // Set the banner to the highest priority status
  }

}
