import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { UserService } from '../../../api/user.service';
import { AdminApiService } from 'src/app/api/adminapi.service';

@Component({
  selector: 'admin-nav-menu',
  templateUrl: './admin-nav-menu.component.html',
  styleUrls: ['./admin-nav-menu.component.css']
})
export class AdminNavMenuComponent {
  toolTipProps = { placement: 'right' };
  logoUrl: string = '/assets/image/logo-conga-2022.svg'; // Initialize to avoid runtime errors
  title: string = ''; // Declare with a specific type
  constructor(private _userService: UserService, private adminApiService: AdminApiService) {

  }


  ngOnInit() {
    this.adminApiService.navMenuOpen.subscribe((data) => {
      if (data) {
        $("#sidebartext-mobile").show();
      }
      else {
        $("#sidebartext-mobile").hide();

      }
    })

    this.adminApiService.GetSettings().subscribe(
      (data) => {

        const details = data.result[0];
        this.title = details?.title;

        if (details?.logoLink)
          this.logoUrl = details?.logoLink;
      },
      (error) => {
        console.error('Error fetching settings:', error);
      }
    );
    this.adminApiService.imageChanged.subscribe(() => {
      this.adminApiService.GetSettings().subscribe(
        (data) => {

          const details = data.result[0];
          this.title = details?.title;
          this.logoUrl = details?.logoLink;
        },
        (error) => {
          console.error('Error fetching settings:', error);
        }
      );
    })
  }

  logoClose() {
    $("#sidebartext").hide();
    $(".main").addClass("maintwo");
  }
  logoCloseMobile() {
    $("#sidebartext-mobile").hide();
  }
  logoOpen() {
    $("#sidebartext").show();
    $(".main").removeClass("maintwo");
  }


  IsAdminRole() {
    return this._userService.GetUserPermission("admin");
  }


}
