import { Inject } from "@angular/core";

export class Globals {
  static homePageURL: string = "/";
  static apiURL: string = "/api/";
  //static apiURL: string ="https://qastatusapi.itechops.com/api/";
  public static timeZone = "(UTC)";
  public static commonRegionName = "Non-Regional";
  public static recaptchaSiteKey = '6Let9A8fAAAAABlcqOjacbT_qEsLBfgglZWpyZtp';
  static homeDtOptions: DataTables.Settings = {
    lengthMenu: [
      [10, 25, 50, 100, -1],
      [10, 25, 50, 100, "All"]
    ],
    ordering: false,
    paging: false,
    dom: 'lrt',
    columns: [
      { "visible": true },
    ],
  };
}
