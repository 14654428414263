import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AdminApiService } from './adminapi.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { StatusApiService } from './statusapi.service';

@Injectable({
  providedIn: 'root'
})
export class sharedService {
  getsetting: any;
  LogoPath: any;
    gettheme: any;

  constructor(
    private cookieService: CookieService,
    private adminApiService: AdminApiService,
    private _statusApiService: StatusApiService,
    private router: Router
  ) { }


  getSetting(): Observable<string> {
    const iSettingCookie = this.cookieService.get('iSetting');

    if (!iSettingCookie) {
      return this._statusApiService.GetSettings()
        .pipe(
          map((data) => {
            this.getsetting = data.result[0];
            const iSetting = this.getsetting;
            this.cookieService.set('iSetting', JSON.stringify(iSetting));
            return iSetting.logoLink;
          })
      );
    } else {
      if(iSettingCookie != 'undefined'){
        const iSetting = JSON.parse(iSettingCookie);

        return of(iSetting.logoLink);
      } else {
        return of(this.LogoPath);
      }
    }
  }

  getTheme():string {

    this._statusApiService.GetSettings().subscribe((data) => {
      const theme = data.result[0];
      if(theme){
        const currentTheme = theme.currentTheme;
        this.cookieService.set('theme',currentTheme, undefined, '/');
      }
      
    });
    return this.cookieService.get('theme');

  }

  getAuth():Observable<string>{
    const iAuthCookie = this.cookieService.get('iAuth');

    if (iAuthCookie) {
      return this.adminApiService.GetSettings()
        .pipe(
          map((data) => {
            this.getsetting = data.result[0];
            const iSetting = this.getsetting;

            this.cookieService.set('iSetting', JSON.stringify(iSetting));
            return iSetting.logoLink;
          })
      );
    }  else {
      const iSetting = JSON.parse(iAuthCookie);

      return of(iSetting.logoLink);
    }
  }


  getAnalyticsScripts(): Observable<string> {
    const iSettingCookie = this.cookieService.get('iSetting');

    if (!iSettingCookie) {
      return this._statusApiService.GetSettings()
        .pipe(
          map((data) => {
            this.getsetting = data.result[0];
            const iSetting = this.getsetting;
            this.cookieService.set('iSetting', JSON.stringify(iSetting));
            return iSetting.googleAnalyticsScript;
          })
      );
    } else {
      if(iSettingCookie != 'undefined'){
        const iSetting = JSON.parse(iSettingCookie);

        return of(iSetting.googleAnalyticsScript);
      } else {
        return of('');
      }
    }
  }

  public loadScript(src: string, isAsync: boolean = true, innerText: string = ''): void {
    const script = document.createElement('script');
    script.src = src;
    script.async = isAsync;
    if (innerText) {
      script.innerHTML = innerText;
    }
    document.head.appendChild(script);
  }
  
  public loadInlineScript(innerText: string): void {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = innerText;
    document.head.appendChild(script);
  }

  // getTheme(): Observable<string> {
  //   const themeFromCookie = this.cookieService.get('theme');

  //   if (themeFromCookie) {
  //     // If theme is already set in the cookie, return it as an observable
  //     return of(themeFromCookie);
  //   } else {
  //     // If theme is not set in the cookie, fetch it from the API
  //     return this.adminApiService.GetSettings().pipe(
  //       map((data) => {
  //         const theme = data.result[0].currentTheme;
  //         this.cookieService.set('theme', theme);
  //         return theme;
  //       })
  //     );
  //   }
  // }





//  getTheme() {

//       const theme= this.cookieService.get('themee');
//       this.cookieService.set('themee',theme);

//     }

  }



