// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 480px) {
    .footer-text {
        text-align: center;
    }
}

`, "",{"version":3,"sources":["webpack://./src/app/ui/status/status.component.css"],"names":[],"mappings":"AAAA;IACI;QACI,kBAAkB;IACtB;AACJ","sourcesContent":["@media (max-width: 480px) {\r\n    .footer-text {\r\n        text-align: center;\r\n    }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
