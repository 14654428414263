import {
  Component,
  Input,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { DailogComponent } from "../home-new3/dailog/dailog.component";

@Component({
  selector: "app-status-custom-expansion-panel2",
  templateUrl: "./status-custom-expansion-panel2.component.html",
  styleUrls: ["./status-custom-expansion-panel2.component.css"],
})
export class StatusCustomExpansionPanel2Component implements OnInit {
  @Input() group: any;
  @Input() isflagInner: boolean;
  @Input() components: any[] = [];
  @Input() isInner: any; // Flag to distinguish between inner and outer panels
  isExpanded = false; // Track the expanded state
  uniqueId: string;
  flag: boolean = true;
  constructor(private renderer: Renderer2) {}

  // Access dialog from parent
  @ViewChild("dialog") dialogComponent!: DailogComponent;

  statusMapping = new Map<string, string>([
    ["Maintenance", "Maintenance"],
    ["Major Outage", "Major Outage"],
    ["Partial Outage", "Partial Outage"],
    ["Degradation", "Degradation"],
    ["Operational", "Operational"],
  ]);

  ngOnInit(): void {
    this.uniqueId = 'accordionFlushExampleOuter_' + Math.random().toString(36).substr(2, 9);
  }

  ngOnChanges(changes: SimpleChanges) {
    // Handle any changes if needed
  }
  toggleCollapse() {
    this.isExpanded = !this.isExpanded; // Toggle the state
  }
  getGroupStatus(group: any) {
    let displayStatus = "Operational";

      if(group.componentStatus === undefined && displayStatus != group.status){
        displayStatus= group.status;
      }
      else{
        for (let status of group.componentStatus) {
          if(displayStatus != status.status && status.status != "Operational"){
            displayStatus = status.status;
          }
        }
      }

    return displayStatus;
  }


  openDialog(region: any) {
    let hasNonNullIncident = false;
  
    if (region.componentStatus != undefined) {
      for (let ca of region.componentStatus) {
        if (ca.incident != null) {
          hasNonNullIncident = true;
          break;  // Exit the loop as soon as we find a non-null incident
        }
       
      }
    }
  
    if (this.dialogComponent && hasNonNullIncident) {
      this.dialogComponent.region = region; // Pass the region data
      this.dialogComponent.openDialog(); // Open the dialog
      this.renderer.setStyle(document.body, 'overflow', 'hidden');
    }
  }
}
