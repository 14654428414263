import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataTableDirective } from 'angular-datatables';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { StatusApiService } from '../../../api/statusapi.service';
import { Globals } from '../../../global';
import { Incident, Region, StatusPageModel, Zone } from '../../../models/StatusPageModel';
import { EventDetailDialogComponent } from '../event-detail-dialog/event-detail-dialog.component';

@Component({
  selector: 'app-home-new1',
  templateUrl: './home_new1.component.html',
  styleUrls: ['./home_new1.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeNew1Component {
  commonRegionName: string = "";
  statusData: StatusPageModel;
  selectedTab: string = "";
  zones: Zone[];
  regions: Region[];
  activeAnnouncements: Incident[];
  refreshClock: any;
  incidents: Incident[];
  maintenances: Incident[];
  searchText: string = "";
  $currentDateTime: Observable<Date> = timer(0, 1000).pipe(map(() => new Date()));
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  public templateDefault;
  toolTipProps = { placement: 'bottom', theme: 'dark', allowHTML: true };
  toolTipDarkProps = { placement: 'top', allowHTML: true };

  tipcontent: string = "<div class='p-2'><label><i class='bi bi-check-lg'> </i><span class='' > Operational </span>";
  tipcontentError: string = "<div class='p-3'><label>15 Dec 2021</label><br/><label class = 'mt-3 bg-light'><i class='bi bi-x-lg text-danger me-2'> </i><span class='me-3' > Partial Outage </span>";

  dtOptions: DataTables.Settings = Globals.homeDtOptions;
  refreshOptions: Array<Object> = [
    { name: '2 Minutes', value: "2" },
    { name: '5 Minutes', value: "5" },
    { name: '10 Minutes', value: "10" }
  ]
  refreshInterval: string = "2";
  timeZone;

  statusMapping = {
    'Maintenance': 'Maintenance',
    'Major Outage': 'Major Outage',
    'Partial Outage': 'Partial Outage',
    'Degradation': 'Degradation',
    'Operational': 'Operational'
};
  constructor(private _statusApiService: StatusApiService, public dialog: MatDialog) {
    this.timeZone = Globals.timeZone;
    this.commonRegionName = Globals.commonRegionName;

  }

  ngOnInit(): void {
    this.getComponentStatus(true);
    this.getActiveAnnouncments();
    this.setUpdateRefreshClock();
  }

  search(event: any) {
    this.searchText = event;

    //this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //  dtInstance.search(event.target.value).draw();
    //});
  }


  getComponentStatus(updateRegionZones = false) {
    this._statusApiService.GetCongaComponentsStatus()
      .subscribe(
        data => {
          if (data && data.success && data.result && data.result.componentGroups
            && data.result.componentGroups.length > 0) {
            this.statusData = data.result;
            this.incidents = this.statusData.incidents;
            this.maintenances = this.statusData.maintenances;

            if (updateRegionZones)
              this.upadteZonesFromStatusData();
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  getGroupStatus(components) {
      for (const status of Object.keys(this.statusMapping)) {
        if (components.find(c => c.componentStatus[0]?.status === status)) {
            return this.statusMapping[status];
        }
    }
  
    // Default to 'Operational' if no matching status is found
    return 'Operational';
  }

  upadteZonesFromStatusData() {
    let totalZones: Zone[] = [];
    this.statusData.regions.forEach((r) => {
      r.zones.forEach((z) => {
        let zone: Zone = {
          regionId: r.regionId,
          regionName: r.regionName,
          sequence: z.sequence,
          zoneId: z.zoneId,
          zoneName: z.zoneName
        };
        totalZones.push(zone);
      });
    });
    this.zones = totalZones;
    this.regions = this.statusData.regions;
    if (!this.selectedTab && this.statusData.regions)
      this.selectedTab = this.statusData.regions[0]?.regionName;
  }

  tabChange(region) {
    this.selectedTab = region;
  }

  refreshRateChange() {
    this.setUpdateRefreshClock();
  }

  setUpdateRefreshClock() {
    if (this.refreshClock)
      clearInterval(this.refreshClock);

    this.refreshClock = setInterval(() => {
      //console.log('executed : ' + this.refreshInterval + " " + new Date());
      this.getComponentStatus();
    }, parseInt(this.refreshInterval) * 1000 * 60);
  }


  getActiveAnnouncments() {
    this._statusApiService.GetActiveAnnouncements()
      .subscribe(
        data => {
          if (data && data.success && data.result) {
            this.activeAnnouncements = data.result;
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  openEventDetail(id) {
    const dialogRef = this.dialog.open(EventDetailDialogComponent,
      {
        panelClass: 'full-dialog',
        data: id,
        width: '70%',
        height: '60%'
      });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  toggleAccordion(event: Event): void {
    const target = event.currentTarget as HTMLElement;
    const content = target.nextElementSibling as HTMLElement;
    content.style.display = content.style.display === 'block' ? 'none' : 'block';
  }
}
