import {
  Component,
  ViewChild,
  ElementRef,
  Input,
  Renderer2,
} from "@angular/core";
import { StatusApiService } from "src/app/api/statusapi.service";
import { Globals } from "src/app/global";

interface AffectedComponent {
  componentId: string;
  componentName: string;
  regionId: string;
  regionName: string;
  zoneId: string;
  zoneName: string;
  createdDate: string;
  isActive: boolean;
}

interface GroupedComponent {
  componentName: string;
  zones: string[];
}

@Component({
  selector: "app-dailog",
  templateUrl: "./dailog.component.html",
  styleUrls: ["./dailog.component.css"],
})
export class DailogComponent {

  @ViewChild("exampleDialog", { static: true })
  dialogRef!: ElementRef<HTMLDialogElement>;
  @Input() region!: any; // Accept the region data
  affectedComponent: AffectedComponent[] = [];
  groupedComponents: GroupedComponent[] = [];
  componentStatus: string = "Operational";
  isAffectedComponentLoading: boolean = true;
  incidentHistoryCreatedDate:any;
  timeZone;

  private statusPriority: { [key: string]: number } = {
    'major outage': 1,
    'partial outage': 2,
    'degradation': 3,
    'maintenance': 4,
    'operational': 5
  };

  constructor(
    private renderer: Renderer2,
    private _statusApiService: StatusApiService
  ) {
    this.timeZone = Globals.timeZone;
  }

  openDialog() {
    // Sort the componentStatus
    if (this.region?.componentStatus) {
      this.sortComponentStatus(this.region.componentStatus);
    }
    if (this.dialogRef) {
      console.log(this.region);
      this.dialogRef.nativeElement.showModal();
    }
    this.setStatus();

    const incidentId =
      this.region?.componentStatus?.[0]?.incident?.incidentId ||
      this.region?.incident?.incidentId ||
      this.region?.componentStatus?.[7]?.incident?.incidentId ||
      "No Incident ID available";
    if (incidentId) {
      this._statusApiService
        .GetIncidentHistory(incidentId)
        .subscribe((data) => {
          if (data && data.success && data.result) {
            this.isAffectedComponentLoading = false;
            this.affectedComponent = data.result.affectedComponents;
            this.groupComponents();
          }
        });
    }
    this._statusApiService.GetIncidentHistory(incidentId).subscribe(
      data => {
        if (data && data.success && data.result) {
          this.incidentHistoryCreatedDate=data.result.startDate;
          this.isAffectedComponentLoading = false;
          this.affectedComponent = data.result.affectedComponents;
          this.groupComponents();

        }
      }
    );

  }

  darkThemeApply: any = false;

  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  ngOnInit() {
    const storedTheme = this.getCookie("theme");
    if (storedTheme) {
      this.darkThemeApply = storedTheme === "Multi-Region";
    } else {
      this.darkThemeApply = false;
    }
  }
  groupComponents() {
    const groupedMap = new Map<string, Set<string>>();

    this.affectedComponent.forEach((component) => {
      if (!groupedMap.has(component.componentName)) {
        groupedMap.set(component.componentName, new Set());
      }
      const zoneString =
        component.zoneName !== "Non-Regional"
          ? component.zoneName
          : component.regionName;
      groupedMap.get(component.componentName)?.add(zoneString);
    });

    this.groupedComponents = Array.from(groupedMap).map(
      ([componentName, zonesSet]) => ({
        componentName,
        zones: Array.from(zonesSet),
      })
    );
  }

  setStatus() {
    this.region.componentStatus.forEach((i) => {
      if (i.status !== this.componentStatus && i.status != "Operational") {
        this.componentStatus = i.status;
      }
    });
  }

  closeDialog() {
    if (this.dialogRef) {
      this.renderer.removeStyle(document.body, "overflow");
      this.dialogRef.nativeElement.close();
    }
  }

  getLastIncidentHistoryDescription(region: any): string {
    const history =
      region?.componentStatus?.[0]?.incident?.incidentHistory ||
      region?.incident?.incidentHistory ||
      [];

    // Find the last non-null description
    const description = history
      .slice()
      .reverse()
      .find((item) => item?.incidentHistoryDescription);

    return (
      description?.incidentHistoryDescription || "No description available"
    );
  }

  getAffectedComponetByIncidentId(incidentID: string) {
    this._statusApiService.GetIncidentHistory(incidentID).subscribe((data) => {
      if (data && data.success && data.result) {
        this.affectedComponent = data.result;
        console.log(this.affectedComponent);
      }
    });
  }

  getIncidentId(region: any): string {
    const incidentId =
      region?.componentStatus?.[0]?.incident?.incidentId ||
      region?.incident?.incidentId ||
      "No Incident ID available";

    return "/event-detail/" + incidentId;
  }

  private sortComponentStatus(componentStatus: any[]) {
    // Preprocess the statuses to replace them with their priority values
    const prioritizedStatus = componentStatus.map(item => ({
      ...item,
      priority: this.statusPriority[item.status.toLowerCase()] || 6 // Default to 6 for unknown statuses
    }));

    // Sort by priority
    prioritizedStatus.sort((a, b) => a.priority - b.priority);

    // Optionally, remove the priority property if not needed later
    for (let i = 0; i < prioritizedStatus.length; i++) {
      delete prioritizedStatus[i].priority; // Remove the priority property
    }

    // Update the original componentStatus with the sorted array
    this.region.componentStatus = prioritizedStatus;
  }
}
