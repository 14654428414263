import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-status-custom-expansion-panel',
  templateUrl: './status-custom-expansion-panel.component.html',
  styleUrls: ['./status-custom-expansion-panel.component.css']
})
export class StatusCustomExpansionPanelComponent implements OnChanges {
  @Input() group: any;
  @Input() components: any [] = [];
  // @Input() index;
  
  panelOpenState: boolean;
  searchText: string = "";
  statusMapping = {
    'Maintenance': 'Maintenance',
    'Major Outage': 'Major Outage',
    'Partial Outage': 'Partial Outage',
    'Degradation': 'Degradation',
    'Operational': 'Operational'
};

  constructor(private host: ElementRef<any>) {
    // this.group.defaultExpanded = false;
  }

  ngOnChanges(changes: SimpleChanges) {
   
  }

  // ngAfterViewInit() {
  //   if (this.group && this.group.length > 0) {
  //     const firstAccordion = this.group[0];
  //     const firstAccordionId = '#flush-collapseOne' + firstAccordion;

  //     // Use JavaScript to open the first accordion programmatically
  //     const firstAccordionElement = document.querySelector(firstAccordionId);
  //     if (firstAccordionElement) {
  //       firstAccordionElement.classList.add('show');
  //     }
  //   }
  // }

  getGroupStatus() {
    //  if(this.components.find(c=>c.componentStatus[0]?.status == 'Maintenance')){
    //   return 'Maintenance';
    //  }

    //  if(this.components.find(c=>c.componentStatus[0]?.status == 'Major Outage')){
    //   return 'Major Outage';
    //  }

    //  if(this.components.find(c=>c.componentStatus[0]?.status == 'Partial Outage')){
    //   return 'Partial Outage';
    //  }

    //  if(this.components.find(c=>c.componentStatus[0]?.status == 'Degradation')){
    //   return 'Degradation';
    //  }

    //  if(this.components.find(c=>c.componentStatus[0]?.status == 'Operational')){
    //   return 'Operational';
    //  }

      for (const status of Object.keys(this.statusMapping)) {
        if (this.components.find(c => c.componentStatus[0]?.status === status)) {
            return this.statusMapping[status];
        }
    }
  
    // Default to 'Operational' if no matching status is found
    return 'Operational';
  }
    
  
}
