import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AdminApiService } from '../../../api/adminapi.service';
import { UserService } from '../../../api/user.service';
import { User } from '../../../models/UserModel';
import { Breadcrumb } from '../models/breadcrumb';
import { BreadcrumbService } from '../services/breadcrumb.service';

@Component({
  selector: 'admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.css']
})
export class AdminHeaderComponent {
  bcLoadedData;
  breadcrumbs$: Observable<Breadcrumb[]>;
  user: User;
  isAdmin: boolean = false;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(private _router: Router,
    private readonly _breadcrumbService: BreadcrumbService,
    private _adminApiService: AdminApiService,
    private _userService: UserService,
    private _changeDetectorRef: ChangeDetectorRef) {

    this.breadcrumbs$ = _breadcrumbService.breadcrumbs$;
  }


  onClick(value: boolean) {
    this._adminApiService.navMenuOpen.next(value)
    }
  ngOnInit(): void {
    this._userService.user$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((user: User) => {
        this.user = user;
        this.isAdmin = this.isAdminRole();
        this._changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  logout() {
    this._userService.removeUser();
    setTimeout(() => {
      window.location.href = "/Auth/Logout";
    },500);
  }
  isAdminRole(): boolean {
    return this._userService.GetUserPermission("admin");
  }

}
