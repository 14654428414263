// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only all and (min-width: 601px) {
    .displayIconNav{
        display: none !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/ui/admin/admin-header/admin-header.component.css"],"names":[],"mappings":"AAAA;IACI;QACI,wBAAwB;IAC5B;AACJ","sourcesContent":["@media only all and (min-width: 601px) {\r\n    .displayIconNav{\r\n        display: none !important;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
