import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusApiService } from '../../../api/statusapi.service';
import { Globals } from '../../../global';
import { Incident } from '../../../models/StatusPageModel';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.css']
})
export class EventDetailComponent implements OnInit {
  eventId: string = '';
  event: Incident;
  timeZone;
  constructor(private _statusApiService: StatusApiService, private route: ActivatedRoute, private router: Router) {
    this.timeZone= Globals.timeZone;
  }

  ngOnInit() {
    this.eventId = this.route.snapshot.paramMap.get('id');
    if (!this.eventId)
      this.router.navigate(['/']);
    this.getEventHistory();
  }


  getEventHistory() {
    this._statusApiService.GetIncidentHistory(this.eventId)
      .subscribe(
        data => {
          if (data && data.success && data.result) {
            this.event = data.result;
          }
          else {
            this.router.navigate(['/']);
          }
          console.log(data);
        },
        error => {
          console.log(error);
          this.router.navigate(['/']);
        }
      );
  }

}
