import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusApiService } from '../../../api/statusapi.service';
import { Globals } from '../../../global';
import { Incident } from '../../../models/StatusPageModel';



interface AffectedComponent {
  componentId: string;
  componentName: string;
  regionId: string;
  regionName: string;
  zoneId: string;
  zoneName: string;
  createdDate: string;
  isActive: boolean;
}

interface GroupedComponent {
  componentName: string;
  zones: string[];
}
@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.css']
})
export class EventDetailComponent implements OnInit {
  eventId: string = '';
  event: Incident;
  timeZone;
  constructor(private _statusApiService: StatusApiService, private route: ActivatedRoute, private router: Router) {
    this.timeZone= Globals.timeZone;
  }
  darkThemeApply:any=false;

  affectedComponent: AffectedComponent[] = [];
  groupedComponents: GroupedComponent[] = [];

  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  ngOnInit() {
    const storedTheme = this.getCookie('theme');

    if (storedTheme) {
      this.darkThemeApply = storedTheme === 'Multi-Region';
    } else {
      this.darkThemeApply = false;
    }
    
    this.eventId = this.route.snapshot.paramMap.get('id');
    if (!this.eventId)
      this.router.navigate(['/']);
    this.getEventHistory();
  }
  groupComponents() {
    const groupedMap = new Map<string, Set<string>>();

    this.affectedComponent.forEach((component) => {
      if (!groupedMap.has(component.componentName)) {
        groupedMap.set(component.componentName, new Set());
      }
      const zoneString =
        component.zoneName !== "Non-Regional"
          ? component.zoneName
          : component.regionName;
      groupedMap.get(component.componentName)?.add(zoneString);
    });

    this.groupedComponents = Array.from(groupedMap).map(
      ([componentName, zonesSet]) => ({
        componentName,
        zones: Array.from(zonesSet),
      })
    );
  }
  getEventHistory() {
    this._statusApiService.GetIncidentHistory(this.eventId)
      .subscribe(
        data => {
          if (data && data.success && data.result) {
            this.event = data.result;
            this.affectedComponent = this.event.affectedComponents || [];
            this.groupComponents();

          }
          else {
            this.router.navigate(['/']);
          }
          console.log(data);
        },
        error => {
          console.log(error);
          this.router.navigate(['/']);
        }
      );
  }
}
