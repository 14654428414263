import { Component } from '@angular/core';

@Component({
  selector: 'admin-footer',
  templateUrl: './admin-footer.component.html',
  styleUrls: ['./admin-footer.component.css']
})
export class AdminFooterComponent {
  currentYear;

  constructor() {
    this.currentYear = new Date().getUTCFullYear();
  }
}
