import { Component, OnInit, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { Globals } from "../../global";
import { AdminApiService } from "src/app/api/adminapi.service";
import { sharedService } from "src/app/api/sharedService";
import { CustomRoutesServiceService } from "src/app/api/CustomRoutesService.service";
import { CookieService } from "ngx-cookie-service";
import { StatusApiService } from "src/app/api/statusapi.service";

@Component({
  selector: "app-status",
  templateUrl: "./status.component.html",
  styleUrls: ["./status.component.css"],
})
export class StatusComponent implements OnInit {
  footerlink: any;
  footer: any;
  linkedinlink: any;
  twitterlink: any;
  SlackLink: any;
  phone: any;
  currentYear;
  homePageURL;

  GlobalAnnouncement: any = null;
  shouldShowAlert: boolean = true;
  darkThemeApply:any=false;

  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  constructor(
    public router: Router,
    private adminApiService: AdminApiService,
    private sharedService: sharedService,
    private customRouterService: CustomRoutesServiceService,
    private cookieService: CookieService,
    private renderer: Renderer2,
    private _statusApiService: StatusApiService
  ) {
    this.currentYear = new Date().getUTCFullYear();
    this.homePageURL = Globals.homePageURL;
    this.currentYear = new Date().getFullYear();
  }
  isLoadingTheme=true
  ngOnInit() {
    // this.GetGoogleAnalyticsScript();
    const storedTheme = this.getCookie('theme');

    if (storedTheme) {
      this.darkThemeApply = storedTheme === 'Multi-Region';
    } else {
      this.darkThemeApply = false;
    }
   this.getAllSetting();
    const hideAlertCookie = this.cookieService.get("GlobalAnnouncement");
    if (hideAlertCookie == "true") {
      this.shouldShowAlert = false;
    } else {
      this.shouldShowAlert = true;
    }
  }

  getAllSetting() {
    this._statusApiService.GetSettings().subscribe((data) => {
      const setting = data.result[0];
      if (setting) {
        this.linkedinlink = setting.linkedinUrl;
        this.twitterlink = setting.twitterId;
        this.footerlink = setting.footerLink;
        this.phone = setting.phoneNo;
        this.SlackLink = setting.slackLink;
        this.footer = setting.footerDescription;
        this.GlobalAnnouncement = setting.globalAnnouncement;
        if (
          setting.globalAnnouncement == null ||
          setting.globalAnnouncement == "null"
        ) {
          this.shouldShowAlert = false;
        }

        if(setting.currentTheme == "Multi-Region")
        {
          this.darkThemeApply=true;
        }
        else{
          this.darkThemeApply=false;

        }
        this.isLoadingTheme=false
       // this.setTheme(setting.currentTheme);
      }
    });
  }

  // setTheme(theme: string) {
  //   this.cookieService.set("theme", theme);
  // }
  setCookie() {
    // Set a cookie to hide the alert for the next 24 hours
    const expirationTime = 24 * 60 * 60; // 24 hours in seconds
    const currentDate = new Date();
    const expirationDate = new Date(
      currentDate.getTime() + expirationTime * 1000
    );
    this.cookieService.set("GlobalAnnouncement", "true", expirationDate);
  }
  GetGoogleAnalyticsScript() {
    this._statusApiService.GetGoogleAnalyticsScript().subscribe((data) => {
      if (data && data.success && data.result) {
        // Assuming data.result is a string containing the full script
        const googleAnalyticsScript = data.result[0].googleAnalyticsScript; // This should contain the full script as a string
        document.body.insertAdjacentHTML("beforeend", googleAnalyticsScript); // Insert the script directly into the body
      } else {
        console.log("No Analytics Script found");
      }
    });
  }
}
