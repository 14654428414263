import { Component, ElementRef, ViewChild } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  NgForm,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { StatusApiService } from "../../../api/statusapi.service";
import { AppComponent } from "../../../app.component";
import { Globals } from "../../../global";
import { Incident, StatusPageModel } from "../../../models/StatusPageModel";
import { EventDetailDialogComponent } from "../event-detail-dialog/event-detail-dialog.component";
import { AdminApiService } from "src/app/api/adminapi.service";
import { ThemeService } from "src/app/services/theme.services";
import { CookieService } from "ngx-cookie-service";
import { EventDetailDialogv2Component } from "../event-detail-dialogv2/event-detail-dialogv2.component";

@Component({
  selector: "app-nav-menu",
  templateUrl: "./nav-menu.component.html",
  styleUrls: ["./nav-menu.component.css"],
})
export class NavMenuComponent {
  @ViewChild("recaptcha", { static: true }) recaptchaElement: ElementRef;

  displayNotificationBar: boolean = false;
  logoUrl = "/assets/image/logo-conga-2022.svg";
  statusData: StatusPageModel;
  incidents: Incident[];
  maintenances: Incident[];
  activeAnnouncements: Incident[];
  totalNotifications: number = 0;
  isNotificationOpened: boolean = false;
  timeZone;
  activeTab: string = "announcements";
  title: string = "";
  suportButtonLink: string = "";
  darkThemeApply: any = false;

  isValidFormSubmitted = null;
  subscribeForm = new UntypedFormGroup({
    email: new UntypedFormControl("", [Validators.required]),
  });

  constructor(
    private _statusApiService: StatusApiService,
    private toastr: ToastrService,
    public router: Router,
    private adminApiService: AdminApiService,
    private dialog: MatDialog,
    public themeService: ThemeService
  ) { }
  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  ngOnInit() {
    this.addRecaptchaScript();
    this.timeZone = Globals.timeZone;
    this.getComponentStatus();
    this.getActiveAnnouncments();
    this.setInitialActiveTab();
    const storedTheme = this.getCookie('theme');

    if (storedTheme) {
      this.darkThemeApply = storedTheme === 'Multi-Region';
    } else {
      this.darkThemeApply = false;
    }


    this._statusApiService.GetSettings().subscribe(
      (data) => {
        const details = data.result[0];


        if (details.currentTheme == "Multi-Region") {
          this.darkThemeApply = true;
        }
        else {
          this.darkThemeApply = false;

        }

        this.title = details?.title;
        this.logoUrl = details?.logoLink;

        this.suportButtonLink = details?.portalUrl;
      },
      (error) => {
        console.error("Error fetching settings:", error);
        this.toastr.error("Failed to fetch settings. Please try again later.");
      }
    );
  }

  ngAfterViewInit() { }

  get f() {
    return this.subscribeForm.controls;
  }

  resolved(event) {
    if (event) this.onFormSubmit();
  }

  onFormSubmit() {
    this.isValidFormSubmitted = false;
    if (this.subscribeForm.invalid) {
      AppComponent.hideLoader();
      return;
    }
    this.isValidFormSubmitted = true;

    const requestPayload = {
      subscriptionValue: this.f.email.value,
      subscriberType: "email",
    };

    AppComponent.showLoader();
    this._statusApiService.AddUpdateSubscriber(requestPayload).subscribe(
      (data) => {
        if (data.success && data.result) {
          window.location.href =
            "/subscription/" + data.result + "?newsubscribe=true";
        }

        setTimeout(() => {
          AppComponent.hideLoader();
        }, 200);
      },
      (error) => {
        if (error && error.error && error.error.message && error.error?.result)
          this.toastr.error(error.error?.result[0], "Error", {
            closeButton: true,
          });
        else if (error && error.error && error.error.message)
          this.toastr.error(error.error.message, "Error", {
            closeButton: true,
          });
        else
          this.toastr.error(
            "Something Went Wrong. Please contact Admin",
            "Error",
            { closeButton: true }
          );

        AppComponent.hideLoader();
      }
    );
  }

  validate(event) {
    window["grecaptcha"].execute();
  }
  setInitialActiveTab() {
    const counts = {
      announcements: this.activeAnnouncements?.length || 0,
      incidents: this.incidents?.length || 0,
      maintenances: this.maintenances?.length || 0,
    };
    // Ensure the data is loaded before making the decision
    if (this.maintenances) {
      if (
        counts.incidents >= counts.maintenances &&
        counts.incidents >= counts.announcements
      ) {
        this.activeTab = "incidents";
      } else if (counts.maintenances > counts.announcements) {
        this.activeTab = "maintenances";
      } else {
        this.activeTab = "announcements";
      }
    }
  }

  setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  renderReCaptch() {
    window["grecaptcha"]?.render(document.getElementById("recaptcha"), {
      sitekey: Globals.recaptchaSiteKey,
      callback: (response) => {
        this.onFormSubmit();
        window["grecaptcha"].reset();
      },
      size: "invisible",
    });
  }

  addRecaptchaScript() {
    window["grecaptchaCallback"] = () => {
      this.renderReCaptch();
    };

    (function (d, s, id, obj) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        obj.renderReCaptch();
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src =
        "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "recaptcha-jssdk", this);
  }

  toggleNotificationBar() {
    this.isNotificationOpened = true;
    this.displayNotificationBar = !this.displayNotificationBar;
    this.setInitialActiveTab();
  }
  closeNotificationTab() {
    this.displayNotificationBar = false
  }


  getComponentStatus() {
    this._statusApiService.GetCongaComponentsStatus().subscribe(
      (data) => {
        if (
          data &&
          data.success &&
          data.result &&
          data.result.componentGroups &&
          data.result.componentGroups.length > 0
        ) {
          this.statusData = data.result;
          this.incidents = this.statusData.incidents;
          this.maintenances = this.statusData.maintenances;
          this.updateNotifications();
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getActiveAnnouncments() {
    this._statusApiService.GetActiveAnnouncements().subscribe(
      (data) => {
        if (data && data.success && data.result) {
          this.activeAnnouncements = data.result;
        }
        this.updateNotifications();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  updateNotifications() {
    this.totalNotifications =
      this.activeAnnouncements?.length +
      this.incidents?.length +
      this.maintenances?.length;
    this.setInitialActiveTab();
  }

  openEventDetail(id) {
    const dialogRef = this.dialog.open(EventDetailDialogv2Component, {
      panelClass: "full-dialog",
      data: id,
      minWidth: '60%',
      maxWidth: '70%', // Set a maximum width
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      // Handle the result if necessary
    });
  }

  redirectToSupport() {
    if (this.suportButtonLink) {
      window.open(this.suportButtonLink, "_blank"); // Opens the link in a new tab
    }
  }
  ThemeChange() {
    this.themeService.theme = !this.themeService.isDark ? 'dark' : 'light';

  }
  isSubscribeModalOpen = false;
  toggleSubscribeModal() {
    this.isSubscribeModalOpen = !this.isSubscribeModalOpen;
  }

  closeSubscribeModal() {
    this.isSubscribeModalOpen = false;
  }

}
