// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/*  */
.section-icons{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.t-green {
  color: #ffffff;
  background-color: #00850d;
  border-radius: 50px;
  padding: 2px 5px;
}

.t-blue {
  color: black;
  background-color: #ffffff;
  border-radius: 50px;
  padding: 2px 5px;
}

.t-orange {
  color: #ffffff;
  background-color: #2063E3;
  border-radius: 50px;
  padding: 2px 5px;
}

.t-red {
  color: #ffffff;
  background-color: #ff0000;
  border-radius: 50px;
  padding: 2px 3px;
}

`, "",{"version":3,"sources":["webpack://./src/app/ui/status/event-detail/event-detail.component.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;AACxB;;AAEA,KAAK;AACL;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".container {\r\n  min-height: 100vh;\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n/*  */\r\n.section-icons{\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  gap: 20px;\r\n}\r\n\r\n.t-green {\r\n  color: #ffffff;\r\n  background-color: #00850d;\r\n  border-radius: 50px;\r\n  padding: 2px 5px;\r\n}\r\n\r\n.t-blue {\r\n  color: black;\r\n  background-color: #ffffff;\r\n  border-radius: 50px;\r\n  padding: 2px 5px;\r\n}\r\n\r\n.t-orange {\r\n  color: #ffffff;\r\n  background-color: #2063E3;\r\n  border-radius: 50px;\r\n  padding: 2px 5px;\r\n}\r\n\r\n.t-red {\r\n  color: #ffffff;\r\n  background-color: #ff0000;\r\n  border-radius: 50px;\r\n  padding: 2px 3px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
