import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from "@angular/router";
import { StatusComponent } from "./status.component";
import { NavMenuComponent } from "./nav-menu/nav-menu.component";
import { HomeComponent } from "./home/home.component";
import { HistoryComponent } from "./history/history.component";
import { DataTablesModule } from "angular-datatables";
import { NgxTippyModule } from "ngx-tippy-wrapper";
import { StatusApiService } from "../../api/statusapi.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { EventDetailComponent } from "./event-detail/event-detail.component";
import { Home2Component } from "./home2/home.component";
import { SubscriptionComponent } from "./subscription/subscription.component";
import { environment } from "../../../environments/environment";
import { CGRZChecklistDatabase } from "../../models/CGRZCheckListDatabase";
import { MatTreeModule } from "@angular/material/tree";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatDialogModule } from "@angular/material/dialog";
import { SearchPipe } from "../../searchFilter";
import { EventDetailDialogComponent } from "./event-detail-dialog/event-detail-dialog.component";
import { SingleRegionHomeComponent } from "./single-region-home/single-region-home.component";
import { StatusCustomExpansionPanelComponent } from "./status-custom-expansion-panel/status-custom-expansion-panel.component";
import { MatAccordion, MatExpansionModule } from "@angular/material/expansion";
import { HomeNew1Component } from "./home_new1/home_new1.component";
import { HomeNew2Component } from "./home_new2/home_new2.component";
import { StatusCustomExpansionPanel2Component } from "./status-custom-expansion-panel2/status-custom-expansion-panel2.component";
import { DailogComponent } from "./home-new3/dailog/dailog.component";
import { HomeNew3Component } from "./home-new3/home-new3.component";
import { EventDetailDialogv2Component } from "./event-detail-dialogv2/event-detail-dialogv2.component";

const routes: Routes = [
  {
    path: "",
    component: StatusComponent,
    children: [
      // { path: '', component: HomeComponent },
      { path: "", component: changeHomeStatusPage() },
      { path: "congastatushome", component: Home2Component },
      { path: "new1", component: HomeNew1Component },
      { path: "new2", component: HomeNew2Component },
      { path: "new3", component: HomeNew3Component },
      { path: "history", component: HistoryComponent },
      { path: "event-detail/:id", component: EventDetailComponent },
      { path: "event-detail", component: EventDetailComponent },
      { path: "subscription/:id", component: SubscriptionComponent },
    ],
  },
];

@NgModule({
  declarations: [
    StatusComponent,
    NavMenuComponent,
    HomeComponent,
    Home2Component,
    HistoryComponent,
    EventDetailComponent,
    SubscriptionComponent,
    SearchPipe,
    EventDetailDialogComponent,
    EventDetailDialogv2Component,
    SingleRegionHomeComponent,
    StatusCustomExpansionPanelComponent,
    HomeNew1Component,
    StatusCustomExpansionPanelComponent,
    StatusCustomExpansionPanel2Component,
    DailogComponent,
    HomeNew2Component,
    HomeNew3Component,
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    MatExpansionModule,
    DataTablesModule,
    NgxTippyModule,
    ReactiveFormsModule,
    FormsModule,
    MatTreeModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDialogModule,
  ],
  providers: [StatusApiService, CGRZChecklistDatabase],
  bootstrap: [StatusComponent],
})
export class StatusModule {}
function changeHomeStatusPage() {
  const theme = getCookieValue("theme");
  if (theme && theme !== "Default") {
    return HomeNew3Component;

  } else {
    return HomeComponent;
  }
}

function getCookieValue(name) {
  const regex = new RegExp(`(^| )${name}=([^;]+)`);
  const match = document.cookie.match(regex);
  if (match) {
    return match[2];
  }
}
