// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.table tbody td,
.table thead th {
  border: #dedede solid 1px !important;
}
.table thead th {
  font-weight: 800;
}

.table tbody {
 border:none;
}

table.dataTable {
    margin-top:0 !important;
    margin-bottom:0 !important;
}

`, "",{"version":3,"sources":["webpack://./src/app/ui/status/home2/home.component.css"],"names":[],"mappings":";AACA;;EAEE,oCAAoC;AACtC;AACA;EACE,gBAAgB;AAClB;;AAEA;CACC,WAAW;AACZ;;AAEA;IACI,uBAAuB;IACvB,0BAA0B;AAC9B","sourcesContent":["\r\n.table tbody td,\r\n.table thead th {\r\n  border: #dedede solid 1px !important;\r\n}\r\n.table thead th {\r\n  font-weight: 800;\r\n}\r\n\r\n.table tbody {\r\n border:none;\r\n}\r\n\r\ntable.dataTable {\r\n    margin-top:0 !important;\r\n    margin-bottom:0 !important;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
