import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { StatusApiService } from "../../../api/statusapi.service";
import { Globals } from "../../../global";
import { ThemeService } from "src/app/services/theme.services";

interface AffectedComponent {
  componentId: string;
  componentName: string;
  regionId: string;
  regionName: string;
  zoneId: string;
  zoneName: string;
  createdDate: string;
  isActive: boolean;
}

interface GroupedComponent {
  componentName: string;
  zones: string[];
}

@Component({
  selector: "app-event-detail-dialogv2",
  templateUrl: "./event-detail-dialogv2.component.html",
  styleUrl: "./event-detail-dialogv2.component.css",
})
export class EventDetailDialogv2Component {
  eventId: string = "";
  // event: Incident;
  event: any;
  timeZone;
  darkThemeApply: any = false;
  lastDescription: string = "No description available";

  isLoading: boolean = true;
  affectedComponent: AffectedComponent[] = [];
  groupedComponents: GroupedComponent[] = [];
  incidentHistoryCreatedDate:any;


  constructor(
    private dialogRef: MatDialogRef<EventDetailDialogv2Component>,
    private themeService:ThemeService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _statusApiService: StatusApiService
  ) {
    this.eventId = data;
    this.timeZone = Globals.timeZone;

    if (this.eventId) {
      this.getEventHistory();
    } else {
      this.dialogRef.close();
    }
    this.getEventHistory();
  }

  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  lightTheme=true

  ngOnInit(): void {
    const storedTheme = this.getCookie('theme');

    if (this.themeService.isDark) {
      this.lightTheme=false
    } else {
      true
    }

    if (storedTheme) {
      this.darkThemeApply = storedTheme === 'Multi-Region';
    } else {
      this.darkThemeApply = false;
    }
  }

  getEventHistory() {
    this.isLoading = true; // Set loading to true before the API call
    this._statusApiService.GetIncidentHistory(this.eventId).subscribe(
      (data) => {
        this.isLoading = false; // Set loading to false after the data is received
        if (data && data.success && data.result) {
          this.event = data.result;
          this.incidentHistoryCreatedDate=data.result.startDate;

          this.affectedComponent = this.event.affectedComponents || [];
          this.groupComponents();
        } else {
          this.dialogRef.close();
        }
      },
      (error) => {
        console.log(error);
        this.isLoading = false; // Set loading to false on error
        this.dialogRef.close();
      }
    );
  }


  groupComponents() {
    const groupedMap = new Map<string, Set<string>>();

    this.affectedComponent.forEach((component) => {
      if (!groupedMap.has(component.componentName)) {
        groupedMap.set(component.componentName, new Set());
      }
      const zoneString =
        component.zoneName !== "Non-Regional"
          ? component.zoneName
          : component.regionName;
      groupedMap.get(component.componentName)?.add(zoneString);
    });

    this.groupedComponents = Array.from(groupedMap).map(
      ([componentName, zonesSet]) => ({
        componentName,
        zones: Array.from(zonesSet),
      })
    );
  }
}

