import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from '../global';

@Injectable({
  providedIn: 'root'
})

export class StatusApiService {
  apiURL = Globals.apiURL;
  constructor(private http: HttpClient) {

  }

  GetCongaComponentsStatus() {
    return this.http.get<any>(this.apiURL + 'statuspage');
  }

  GetActiveAnnouncements() {
    return this.http.get<any>(this.apiURL + 'statuspage/activeannouncement');
  }

  GetStatusHistory(params) {
    return this.http.post<any>(this.apiURL + 'statuspage/statushistory', params);
  }

  GetStatusHistoryMetadata() {
    return this.http.get<any>(this.apiURL + 'statuspage/statushistorymetadata');
  }

  GetIncidentHistory(param) {
    return this.http.get<any>(this.apiURL + 'statuspage/incidenthistory/' + param);
  }

  AddUpdateSubscriber(params) {
    return this.http.post<any>(this.apiURL + 'subscriber/', params);
  }

  GetSubscriber(subscriberId) {
    return this.http.get<any>(this.apiURL + 'subscriber/getsubscriber/' + subscriberId);
  }

  ConfirmSubscriber(param) {
    return this.http.put<any>(this.apiURL + 'subscriber/confirm/' + param, null);
  }

  Unsubscribe(param) {
    return this.http.put<any>(this.apiURL + 'subscriber/unsubscribe/' + param, null);
  }

  ResendConfirmationEmail(param) {
    return this.http.put<any>(this.apiURL + 'subscriber/resendconfirmationemail/' + param, null);
  }

  GetSubscriberDetails(param) {
    return this.http.get<any>(this.apiURL + 'subscriber/getsubscriber/' + param);
  }

  GetAllComponentGroupsWithRegionAndZone() {
    return this.http.post<any>(this.apiURL + 'subscriber/getallcomponentgroupswithregionandzone', null);
  }
  GetGoogleAnalyticsScript() {
    return this.http.get<any>(this.apiURL + 'statuspage/getGoogleAnalyticsScript');
    //return this.http.get<any>(this.apiURL + 'admin/setting/getGoogleAnalyticsScript');
  }
  GetSettings() {
    return this.http.get<any>(this.apiURL + 'statuspage/settings');
  }
}
