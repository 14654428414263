import { Component, OnInit } from '@angular/core';
import { AdminApiService } from 'src/app/api/adminapi.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  
  constructor(private _adminApiService :AdminApiService) { }
  onMainClick() {
      this._adminApiService.navMenuOpen.next(false)
  }
  ngOnInit(): void {
  }

}
