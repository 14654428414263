import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  OnDestroy,
  AfterViewInit,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  NgForm,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { DataTableDirective } from "angular-datatables";
import { CookieService } from "ngx-cookie-service";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import { UserService } from "src/app/api/user.service";
import { AppComponent } from "src/app/app.component";
import { AddUser } from "src/app/models/UserModel";
import { AdminApiService } from "../../../api/adminapi.service";
import { HttpParams } from "@angular/common/http";

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.css"],
})
export class UserComponent implements OnInit, OnDestroy, AfterViewInit {
  UserData: any;
  userRolesFromDatabase: any[] = [
    { name: "ReadWriteOnly" },
    { name: "ReadOnly" },
    { name: "Admin" },
  ];
  @ViewChild(DataTableDirective, { static: false })
  datatableElement!: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  inputText: string = "";
  editedLabel: string = "";
  editIndex: number = -1;
  labels: string[] = [];
  selectedRole: string = this.userRolesFromDatabase[0].name;
  isInviteUserModel: boolean = true;

  Inviteform!: FormGroup;
  isValidFormSubmitted: boolean | null = null;
  @ViewChild("exampleModal") exampleModal!: ElementRef;

  @ViewChild("exampleNewModal") closebutton!: ElementRef;

  constructor(
    private userApi: UserService,
    private toastr: ToastrService,
    private router: Router,
    private _userService: UserService,
    private adminapi: AdminApiService,
    private cookieService: CookieService
  ) {
    this.Inviteform = new UntypedFormGroup({
      Email: new UntypedFormControl(""),
      rols: new UntypedFormControl(""),
    });
  }
  Loginuser: any;
  LoginuserID: any;

  ngOnInit(): void {
    this.dtOptions = {
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 100, -1],
        [10, 25, 50, 100, "All"],
      ],
      ordering: true,
      searching: true,
    };
    this.getAllUsers();
  }

  getCookieValue(cookieName: string): string {
    return this.cookieService.get(cookieName);
  }

  HideLoginUser() {
    const myCookieValue = this.getCookieValue("loggedInUser");
    const parsedCookie = JSON.parse(myCookieValue);
    const userId = parsedCookie.userId;
    this.LoginuserID = userId;
    const userName = parsedCookie.username;
    this.Loginuser = userName;
  }

  private reloadTable(): void {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  getAllUsers(): void {
    this.userApi.GetAlluser().subscribe(
      (data: any) => {
        if (data.success && data.result) {
          console.log("Fetched Users Data: ", data.result);
          this.UserData = data.result;
          this.reloadTable();
          this.HideLoginUser();
        } else {
          this.handleError("Failed to fetch user data");
        }
      },
      (error) => {
        this.handleError(
          "An error occurred while fetching user data: " + error
        );
      }
    );
  }

  toggleActive(user: any): void {
    const updatedUser = { ...user, isActive: user.isActive };
    this.updateUser(updatedUser, "isActive");
  }

  togglerole(user: any): void {
    this.updateUser(user, "role");
  }

  private updateUser(user: any, updateType: string): void {
    this.userApi.UpdateroleAndisActiveuser(user).subscribe(
      (data: any) => {
        if (data) {
          this.handleSuccess(`User ${updateType} updated successfully`);
        } else {
          this.handleError(`Failed to update ${updateType}`);
          if (updateType === "isActive") {
            user.isActive = !user.isActive;
          }
        }
      },
      (error) => {
        this.handleError("An error occurred during update: " + error);
        if (updateType === "isActive") {
          user.isActive = !user.isActive;
        }
      }
    );
  }

  private handleSuccess(message: string): void {
    this.toastr.success(message, "Success", { closeButton: true });
  }

  isAdminRole() {
    return this._userService.GetUserPermission("admin");
  }

  handleError(errorMessage: string): void {
    console.error(errorMessage);
  }

  get f() {
    return this.Inviteform.controls;
  }

  checkValidation() {
    this.f.Email.setValidators([
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
    ]);
  }

  isSaveDisabled(): boolean {
    return this.labels.length === 0;
  }

  addLabel() {
    if (this.inputText.trim() !== "") {
      const emailControl = new FormControl(this.inputText, Validators.email);
      if (emailControl.valid) {
        this.Inviteform.updateValueAndValidity();
        this.isValidFormSubmitted = false;
        this.labels.push(this.inputText);
        this.inputText = "";
      }
    }
  }

  removeLabel(index: number) {
    this.labels.splice(index, 1);
  }

  editLabel(index: number) {
    this.editedLabel = this.labels[index];
    this.editIndex = index;
  }

  saveLabel(index: number) {
    if (this.editedLabel.trim() !== "") {
      const emailControl = new FormControl(this.editedLabel, Validators.email);
      if (emailControl.valid) {
        this.labels[index] = this.editedLabel;
        this.editIndex = -1;
        this.editedLabel = "";
      }
    }
  }

  cancelEdit() {
    this.editIndex = -1;
    this.editedLabel = "";
  }

  save() {
    const userData = {
      emails: this.labels,
      role: this.selectedRole,
    };

    AppComponent.showLoader();

    this._userService.saveInviteUser(userData).subscribe(
      (data) => {
        console.log(data);
        this.toastr.success("User Invited Successfully", "Success", {
          closeButton: true,
        });
        this.labels = [];
        this.selectedRole = "ReadOnly";
        this.isInviteUserModel = false;
        this.getAllUsers();
        AppComponent.hideLoader();
      },
      (error) => {
        if (error && error.error && error.error.message) {
          this.toastr.error(error.error.message, "Error", {
            closeButton: true,
          });
          this.labels = [];
          this.selectedRole = "ReadOnly";
          this.isInviteUserModel = false;
          this.getAllUsers();
        } else {
          this.toastr.error(
            "Something Went Wrong. Please contact Admin",
            "Error",
            { closeButton: true }
          );
        }
        AppComponent.hideLoader();
      }
    );
  }

  saveAdduser(form: NgForm): void {
    if (form.valid) {
      const newUser: AddUser = {
        firstName: form.value.firstName,
        lastName: form.value.lastName,
        email: form.value.email,
        PhoneNumber: form.value.phoneNo,
        role: form.value.userRole,
      };

      this._userService.addUser(newUser).subscribe({
        next: (response) => {
          this.toastr.success("User Save successfully", "Success");
          this.closebutton.nativeElement.click();
          this.getAllUsers();
        },
        error: (error) => {
          this.toastr.error(error.error.message, "Error", {
            closeButton: true,
          });
        },
      });
    }
  }


  resetForm(form: NgForm) {
    form.resetForm();
    setTimeout(() => {
      this.selectedRole = "ReadWriteOnly";
    });
  }

  getCurrentDate(): string {
    const date = new Date();
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  }
  downloadFile(): void {
    this.adminapi.DownloadCsv().subscribe(response => {
      const blob = new Blob([response], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'StatuspageUsers-' + this.getCurrentDate() + '.csv';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    });
  }

  deleteUser(userId: string): void {
    if (confirm('Are you sure you want to delete this user?')) {
      AppComponent.showLoader();
      
      this._userService.deleteUser(userId).subscribe({
        next: (response: any) => {
          if (response.success) {
            this.toastr.success('User deleted successfully', 'Success');
            this.getAllUsers(); // Refresh the user list
          } else {
            this.toastr.error(response.message || 'Failed to delete user', 'Error');
          }
          AppComponent.hideLoader();
        },
        error: (error) => {
          this.toastr.error(error.error.message || 'Failed to delete user', 'Error');
          AppComponent.hideLoader();
        }
      });
    }
  }
}
