// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    width: 100%;
    /* max-width: 960px;  */
    /* margin: 0 auto; */
    display: flex;
    padding: 2rem 10px 0rem 10px;
    flex-wrap: wrap; /* Enable wrapping for columns */
}

.zIndex-1000 {
    z-index: 1000 !important;
}

.incidentHistory{
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 20px 40px rgba(9,9,9,0.1);
    margin-bottom: 2.5rem;
}

`, "",{"version":3,"sources":["webpack://./src/app/ui/status/single-region-home/single-region-home.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,uBAAuB;IACvB,oBAAoB;IACpB,aAAa;IACb,4BAA4B;IAC5B,eAAe,EAAE,gCAAgC;AACrD;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,yCAAyC;IACzC,qBAAqB;AACzB","sourcesContent":[".container {\r\n    width: 100%;\r\n    /* max-width: 960px;  */\r\n    /* margin: 0 auto; */\r\n    display: flex;\r\n    padding: 2rem 10px 0rem 10px;\r\n    flex-wrap: wrap; /* Enable wrapping for columns */\r\n}\r\n\r\n.zIndex-1000 {\r\n    z-index: 1000 !important;\r\n}\r\n\r\n.incidentHistory{\r\n    background-color: #fff;\r\n    padding: 20px;\r\n    border-radius: 8px;\r\n    box-shadow: 0px 20px 40px rgba(9,9,9,0.1);\r\n    margin-bottom: 2.5rem;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
