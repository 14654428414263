import { ChangeDetectorRef, Component, Renderer2 } from "@angular/core";
import { sharedService } from "./api/sharedService";
import { ThemeService } from "./services/theme.services";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent {
  title = "Status Page";
  public static loading: boolean = false;

  constructor(private cdref: ChangeDetectorRef,private sharedService: sharedService,private themeService:ThemeService,    private renderer: Renderer2) {
  }

  ngOnInit() {
    this.sharedService.getTheme();

    this.sharedService.getAnalyticsScripts().subscribe((scriptContent) => {
      const googleAnalyticsID = this.extractGoogleAnalyticsID(scriptContent);

    // Load the Google Analytics script dynamically
    this.sharedService.loadScript('https://www.googletagmanager.com/gtag/js?id='+ googleAnalyticsID);

    // Inject the inline script for Google Analytics initialization
    const gtagScript = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '`+googleAnalyticsID+`');
    `;
    this.sharedService.loadInlineScript(gtagScript);

    });
    this.themeService.themeChanged.subscribe(()=>{
      this.applyTheme();
    })
  }

  extractGoogleAnalyticsID(content: string): string | null {
    if (!content) {
      return null;
    }
    const regex = /G-\w+/;
    const match = content.match(regex);
    return match ? match[0] : null;
  }
  ngAfterViewInit(){
    this.sharedService.getTheme();

  }
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  public static showLoader() {
    if (AppComponent.loading != true) {
      AppComponent.loading = true;

      setTimeout(() => {
        AppComponent.hideLoader();
      }, 35000);
    }
  }

  public static hideLoader() {
    if (AppComponent.loading != false) {
      AppComponent.loading = false;
    }
  }

  getLoading() {
    return AppComponent.loading;
  }

  public static copyLink(val: string) {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = window.location.origin + val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }
  applyTheme() {
    if (this.themeService.isDark) {
      this.renderer.addClass(document.body, 'dark');
    } else {
      this.renderer.removeClass(document.body, 'dark');
    }
  }
}
