// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-form-field + .mat-form-field {
  margin-left: 8px;
}

.mat-expansion-panel .mat-expansion-panel-header-title {
  color: black;
  align-items: center;
}

.mat-expansion-panel .mat-expansion-panel-header-description {
  font-weight: lighter;
  color: black;
}

.mat-expansion-panel {
  border: 1px solid;
  box-shadow:none;
}

.mat-expansion-panel-header {
  color: black;
  background-color: #ffffff;
  border-bottom : 1px solid;
}

mat-expansion-panel-header.mat-expanded,
mat-expansion-panel-header.mat-expanded:focus {
  background-color: #ffffff
}

.mat-expansion-panel:not(.mat-expanded)
  .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
  background-color: #ffffff
}

.mat-expansion-panel.mat-expanded
  .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
  background-color: #ffffff
}

.mat-expansion-panel:not(.mat-expanded) {
  /* border-radius: 8px !important; */
  margin: 16px 0;
}

/* .mat-expansion-panel.mat-expanded .mat-expansion-panel-header {
  border-radius: 8px 8px 0 0 !important;
} */

:host ::ng-deep .mat-expansion-panel-body {
  background-color: white !important;
}
.text-color{
  color: #0f2771 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/ui/status/status-custom-expansion-panel/status-custom-expansion-panel.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;EACpB,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,yBAAyB;AAC3B;;AAEA;;EAEE;AACF;;AAEA;;EAEE;AACF;;AAEA;;EAEE;AACF;;AAEA;EACE,mCAAmC;EACnC,cAAc;AAChB;;AAEA;;GAEG;;AAEH;EACE,kCAAkC;AACpC;AACA;EACE,yBAAyB;AAC3B","sourcesContent":[".mat-form-field + .mat-form-field {\r\n  margin-left: 8px;\r\n}\r\n\r\n.mat-expansion-panel .mat-expansion-panel-header-title {\r\n  color: black;\r\n  align-items: center;\r\n}\r\n\r\n.mat-expansion-panel .mat-expansion-panel-header-description {\r\n  font-weight: lighter;\r\n  color: black;\r\n}\r\n\r\n.mat-expansion-panel {\r\n  border: 1px solid;\r\n  box-shadow:none;\r\n}\r\n\r\n.mat-expansion-panel-header {\r\n  color: black;\r\n  background-color: #ffffff;\r\n  border-bottom : 1px solid;\r\n}\r\n\r\nmat-expansion-panel-header.mat-expanded,\r\nmat-expansion-panel-header.mat-expanded:focus {\r\n  background-color: #ffffff\r\n}\r\n\r\n.mat-expansion-panel:not(.mat-expanded)\r\n  .mat-expansion-panel-header:hover:not([aria-disabled=\"true\"]) {\r\n  background-color: #ffffff\r\n}\r\n\r\n.mat-expansion-panel.mat-expanded\r\n  .mat-expansion-panel-header:hover:not([aria-disabled=\"true\"]) {\r\n  background-color: #ffffff\r\n}\r\n\r\n.mat-expansion-panel:not(.mat-expanded) {\r\n  /* border-radius: 8px !important; */\r\n  margin: 16px 0;\r\n}\r\n\r\n/* .mat-expansion-panel.mat-expanded .mat-expansion-panel-header {\r\n  border-radius: 8px 8px 0 0 !important;\r\n} */\r\n\r\n:host ::ng-deep .mat-expansion-panel-body {\r\n  background-color: white !important;\r\n}\r\n.text-color{\r\n  color: #0f2771 !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
