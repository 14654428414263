
/**
 * Node for item
 */
 export class ItemNode {
  children: ItemNode[];
  item: RZItem;
}

/**  node with expandable and level information */
export class ItemFlatNode {
  level: number;
  expandable: boolean;
  item: RZItem;
}



export class CGItemFlatNode {
  level: number;
  expandable: boolean;
  item: CGRZItem;
}

export class CGItemNode {
  children: CGItemNode[];
  item: CGRZItem;
}

export class RZItem{
  regionId: string;
  regionName: string;
  zoneId: string;
  zoneName: string;
  regionZoneName: string;
}

export class RegionInfo {
  regionName: string;
  regionId: string;
  zones: ZoneInfo[];
}

export class ZoneInfo {
  zoneName: string;
  zoneId: string;
}

export class ComponentInfo {
  componentName: string;
  componentId: string;
  componentStatus: CGRZItem[];
}



export class CGRZItem {
  componentGroupId: string;
  componentGroupName: string;
  regionId: string;
  regionName: string;
  zoneId: string;
  zoneName: string;
  componentId: string;
  componentName: string;
  name: string;
}

export class ComponentDetail {
  regionId: string;
  regionName: string;
  zoneId: string;
  zoneName: string;
  componentId: string;
  componentName: string;
}
