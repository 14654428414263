import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Globals } from '../global';
import { AddTag } from '../ui/admin/components/component-detail/component-detail.component';
import { param } from 'jquery';
import { SourceInput } from '../ui/admin/events/event-models/source';

@Injectable({
  providedIn: 'root'
})

export class AdminApiService {
  apiURL = Globals.apiURL;
  constructor(private http: HttpClient) {

  }

  public imageChanged: BehaviorSubject<any>=new BehaviorSubject<any>(null);
  public navMenuOpen: BehaviorSubject<any>=new BehaviorSubject<any>(false);

  //* Reports *//
  GetReport(params) {
    return this.http.post<any>(this.apiURL + 'admin/reports', params);
  }

  GetUptimeReport(params) {
    return this.http.post<any>(this.apiURL + 'admin/reports/getuptimereports', params);
  }

  GetReportMetadata() {
    return this.http.get<any>(this.apiURL + 'admin/reports/metadata');
  }
  // Add this method for the monthly bar chart data
  GetMonthlyBarChartData(params) {
    return this.http.post<any>(this.apiURL + 'admin/reports/GetUptimeReportsMonthly', params);
  }
  //* Reports *//



  //* Components *//

  GetAllComponentsAndGroups(params) {
    return this.http.post<any>(this.apiURL + 'admin/component/allcomponentandgroup', params);
  }

  GetAllComponentGroups() {
    return this.http.get<any>(this.apiURL + 'admin/componentgroup');
  }

  GetComponentGroupById(param) {
    return this.http.get<any>(this.apiURL + 'admin/componentgroup/id/' + param);
  }

  GetComponentStatusMetadata() {
    return this.http.get<any>(this.apiURL + 'admin/component/getcomponentstatusmetadata');
  }

  GetAllActiveTags() {
    return this.http.get<any>(this.apiURL + 'admin/component/getallactivetags');
  }

  addTags(tag: AddTag) {
    return this.http.post<any>(this.apiURL + 'admin/component/addtag', tag);
  }

  AddComponentGroup(params) {
    return this.http.post<any>(this.apiURL + 'admin/componentgroup', params);
  }

  UpdateComponentGroup(params) {
    return this.http.put<any>(this.apiURL + 'admin/componentgroup', params);
  }

  AddComponent(params) {
    return this.http.post<any>(this.apiURL + 'admin/component', params);
  }

  UpdateComponent(params) {
    return this.http.put<any>(this.apiURL + 'admin/component', params);
  }

  GetComponentById(param) {
    return this.http.get<any>(this.apiURL + 'admin/component/id/' + param);
  }

  //* Components *//

  //* Incidents *//

  GetAllIncidents(params) {
    return this.http.post<any>(this.apiURL + 'admin/incident/getallincidents', params);
  }

  GetIncidentMetadata() {
    return this.http.get<any>(this.apiURL + 'admin/incident/getincidentmetadata');
  }





  GetAllRegionAndZones() {
    return this.http.get<any>(this.apiURL + 'admin/regionzone/getall');
  }

  AddIncident(params) {
    return this.http.post<any>(this.apiURL + 'admin/incident', params);
  }

  UpdateIncident(params) {
    return this.http.put<any>(this.apiURL + 'admin/incident', params);
  }

  DeleteIncident(params) {
    return this.http.put<any>(this.apiURL + 'admin/incident/Delete', params);
  }

  UpdateIncidentHistory(params: any) {

    var form_data = new FormData();

    for (var key in params) {
      form_data.append(key, params[key]);
    }
    return this.http.put<any>(this.apiURL + 'admin/incident/updatehistory', form_data);
  }

  GetIncidentById(param) {
    return this.http.get<any>(this.apiURL + 'admin/incident/id/' + param);
  }

  GetAllComponentGroupsWithRegionAndZone() {
    return this.http.get<any>(this.apiURL + 'admin/regionzone/getallcomponentgroupswithregionandzone');
  }

  downloadAttachmentFile(attachmentId): Observable<Blob> {
    const options = { responseType: 'blob' as 'json' }
    return this.http.get<Blob>(this.apiURL + 'admin/incident/download/' + attachmentId, options);
  }

  //* Incidents *//


  //* Subscribers *//

  GetAllSubscribers(params) {
    return this.http.post<any>(this.apiURL + 'admin/subscriber/list', params);
  }


  GetMonthlysubsChartData(params: any) {
    return this.http.post<any>(this.apiURL + 'admin/subscriber/GetMonthlysubsChartData', params);
  }

  UpdateSubscriberActiveState(params) {
    return this.http.put<any>(this.apiURL + 'admin/subscriber/updatesubscriberactivestate', params);
  }

  //* Subscribers *//

  logout() {
    return this.http.post<any>(this.apiURL.replace('/api/', '/') + 'auth/logout', null);
  }
    //* Settings*//
    AddUpdateSettings(param) {
      return this.http.post<any>(this.apiURL + 'admin/setting', param);
    }
    GetUpdadeImg(params){
      return this.http.put<any>(this.apiURL + 'admin/setting', params);
    }
    //UpdateSettings(param) {
    //  return this.http.put<any>(this.apiURL + 'admin/setting', param);
    //}
    GetSettings() {
      return this.http.get<any>(this.apiURL + 'admin/setting');
    }


    DownloadCsv(){
      return this.http.get(this.apiURL+'admin/user/download',{ responseType: 'blob' })
    }

    // DashBoard API

    GetAllDashboard(){
      return this.http.get(this.apiURL+'admin/Dashboard/allDashboard');
    }
    addSourece(source: SourceInput) {
      return this.http.post<any>(this.apiURL + 'admin/incident/addsource', source);
    }


}
