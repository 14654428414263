import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { CounterComponent } from './ui/others/counter/counter.component';
import { FetchDataComponent } from './ui/others/fetch-data/fetch-data.component';
import { MainModule } from './ui/main/main.module';
import { AdminModule } from './ui/admin/admin.module';
import { DataTablesModule } from 'angular-datatables';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { MainComponent } from './ui/main/main.component';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StatusModule } from './ui/status/status.module';
import { MatNativeDateModule } from '@angular/material/core';

const routes = [
  {
    path: 'admin',
    loadChildren: () => import('./ui/admin/admin.module').then(m => m.AdminModule),
  },
  { path: '', loadChildren: () => import('./ui/status/status.module').then(m => m.StatusModule) },
  //{ path: 'old', loadChildren: () => import('./ui/main/main.module').then(m => m.MainModule) },
  { path: '**', redirectTo: '/' },
];

const routeOptions: ExtraOptions = { scrollPositionRestoration: 'enabled'};

@NgModule({ declarations: [
        AppComponent,
        CounterComponent,
        FetchDataComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        FormsModule,
        StatusModule,
        MainModule,
        AdminModule,
        NgxTippyModule,
        BrowserAnimationsModule,
        MatNativeDateModule,
        ToastrModule.forRoot(),
        RouterModule.forRoot(routes, routeOptions)], providers: [CookieService, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule { }
