// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
  overflow:hidden;
}


.top-bar-new {
  display:none;
}
`, "",{"version":3,"sources":["webpack://./src/app/ui/admin/admin.component.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;;AAGA;EACE,YAAY;AACd","sourcesContent":[".main {\r\n  overflow:hidden;\r\n}\r\n\r\n\r\n.top-bar-new {\r\n  display:none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
